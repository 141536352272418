import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import Alert from "./Alert";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import Icon from "./Icon";
import L from "./Lang";
import LoadingAlert from "./LoadingAlert";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class VisitEdit extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            ships: []
        };
        this.submit = this.submit.bind(this);
        this.pullShippingList = this.pullShippingList.bind(this);
        this.checkTax = this.checkTax.bind(this);
    }

    componentDidMount() {
        NavMenuGlobal.setPath('Planning,/console/visit;Schedule Visit');
        qreq.get('/api/customer/list', j => {
            if (j.list)
                this.setState({ customers: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }


    pullShippingList(id) {
        this.setState({ ships: [], shipsLoading: true, taxInfo: null });
        qreq.get('/api/customer/shipping/list?custID=' + id, j => {
            if (j.list) {
                this.setState({ ships: j.list });
                if(j.list.length === 1)
                    this.setState({ info: {...this.state.info, customerShippingID: j.list[0].id }});
            }
            else
                this.alert(j.errorMessage);
            this.setState({ shipsLoading: false });
        }, () => {
            this.unkownErrorCallback();
            this.setState({ shipsLoading: false });
        });
    }

    checkTax(custID, shipID) {
        qreq.get('/api/customer/tax/check?custID=' + custID + '&shipID=' + shipID, j => {
            if(j.errorCode === 6)
                this.setState({ taxInfo: { errorMessage: 'Tax information not found.' }});
            else if(j.item)
                this.setState({ taxInfo: {...j.item, errorMessage: j.item.isExpired ? 'Tax information is expired.' : null}});
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
        this.setState({ info: { ...this.state.info } })
    }

    submit(e) {
        if (e) e.preventDefault();
        var info = { ...this.state.info };
        if (!info.plan_date)
            info.plan_date = Globals.toISOLocalDateString(new Date());
        console.log('plan_date', info.plan_date);
        qreq.post('/api/visit/save', info, j => {
            if(j.errorCode === 9) {
                this.alert(<L>Visit already exists for this date.</L>);
            }
            else if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success(<L>Visit has been added.</L>);
                this.goBack();
            }
        }, this.unkownErrorCallback);
    }

    render() {
        return <div className="container-fluid">
            <form onSubmit={this.submit}>
                <Card title="Visit">
                    {this.state.customers ? <FormInput model={this.state.info} name="customerID" label="Customer" type="select-search" options={this.state.customers} onChange={this.pullShippingList} required /> : <LoadingAlert />}
                    {this.state.shipsLoading ? <LoadingAlert /> : this.state.ships ? <FormInput model={this.state.info} name="customerShippingID" label="Shipping" type="select" options={this.state.ships} required onChange={v => this.checkTax(this.state.info.customerID, v)} /> : null}
                    {this.state.taxInfo && this.state.taxInfo.errorMessage ? <Alert type="warning">{this.state.taxInfo.errorMessage}</Alert> : null}
                    <FormInput model={this.state.info} name="plan_date" label="Date" type="date" required />
                </Card>
                <div className="my-3 d-flex gap-1 app-form-actions">
                    <div>
                        <button type="button" className="btn btn-secondary btn-lg" onClick={this.goBack}><Icon icon="faArrowLeft" /> <L>Back</L></button>
                    </div>
                    <div className="flex-fill text-end">
                        <button type="submit" className="btn btn-primary btn-lg" disabled={!this.state.info.customerID || !this.state.info.customerShippingID}><Icon icon="faSave" /> <L>Save</L></button>
                    </div>
                </div>
            </form>
        </div>;
    }
}

export default withParamsAndNavigate(VisitEdit);