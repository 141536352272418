import Globals from "../../shared/Globals";
import { qreq } from "../../shared/qrequest";
import BaseCom from "../BaseCom"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, AreaChart, BarChart, Bar, PieChart, Pie } from 'recharts';
import L from "../Lang";

export default class SalesVsQuoteChart extends BaseCom {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    if (!this.props.useExternalData) {
      qreq.get('/api/chart/sales-vs-quote?dt=' + Globals.getLocalDateString(), j => {
        if (j.list)
          j.list.forEach(i => {
            if (i.fillColor)
              i.fill = i.fillColor;
          });
          this.setState({ data: j.list });
      });
    }
    else
      this.setState({ data: this.props.data });
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data && this.props.useExternalData) {
      this.setState({ data: this.props.data });
    }
  }

  render() {
    if (!this.state.data)
      return null;
    return <>
      <h1 className="text-center text-secondary"><L>Sales/Quote</L></h1>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart
          width={500}
          height={300}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <Tooltip content={a => a && <div className="bg-light border border-dark p-3">
            {a.payload && a.payload.length > 0 && <div style={{color: a.payload[0].payload.fill}}>{Globals.getLang(a.payload[0].name)} {Globals.formatPrice(a.payload[0].value)}</div>}
          </div>} />
          <Legend />
          <Pie data={this.state.data} type="monotone" dataKey="dx" nameKey="name" stroke="#828aca" fillOpacity={1} label />
        </PieChart>
      </ResponsiveContainer>
    </>;
  }
}