import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import FormLabel from "./FormLabel";
import countries from "../dicts/countries.json"
import statesUSA from "../dicts/states-usa.json"
import statesCanada from "../dicts/states-canada.json"

export default class Address extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            typeList: []
        };
        this.title = props.title;
        if (!this.title)
            this.title = 'Address';
        this.state.readOnly = props.readOnly ? props.readOnly : false;
        this.onChange = props.onChange;
        this.onCountryChange = this.onCountryChange.bind(this);
    }



    onCountryChange(obj) {
        this.props.item.country = obj;
        this.setState({ item: this.state.item });
        if (this.props.onChange)
            this.props.onChange(obj);
    }


    render() {
        if (this.props.readOnly) {
            return (<Card title={this.props.title ?? 'Address'}>
                <div className="row">
                    <div className="col-md">
                        <FormLabel model={this.props.item.address1} label="Address Line 1" name="address1" type="text" required={this.props.required} />
                    </div>
                    <div className="col-md">
                        <FormLabel model={this.props.item.address2} label="Address Line 2" name="address2" type="text" required={false} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <FormLabel model={this.props.item.city} label="City" name="city" type="text" required={this.props.required} />
                    </div>
                    <div className="col-md">
                        <FormLabel model={this.props.item.state} label="State" name="state" type="text" required={this.props.required} />
                    </div>
                    <div className="col-md">
                        <FormLabel model={this.props.item.country} label="Country" name="country" type="text" required={this.props.required} />
                    </div>
                    <div className="col-md">
                        <FormLabel model={this.props.item.zipCode} label="Postal Code" name="zipCode" type="text" required={this.props.required} />
                    </div>
                </div>  
            </Card>);
        }

        return (
            <Card title={this.props.title ?? 'Address'}>
                        <FormInput model={this.props.item} label="Address Line 1" name="address1" type="text" required={this.props.required} onChange={this.props.onChange} labelAsPlaceholder={this.props.labelAsPlaceholder} disabled={this.props.disabled} maxLength={40} />
                        <FormInput model={this.props.item} label="Address Line 2" name="address2" type="text" required={false} onChange={this.props.onChange} labelAsPlaceholder={this.props.labelAsPlaceholder} disabled={this.props.disabled} maxLength={40} />
                        <FormInput model={this.props.item} label="Address Line 3" name="address3" type="text" required={false} onChange={this.props.onChange} labelAsPlaceholder={this.props.labelAsPlaceholder} disabled={this.props.disabled} maxLength={40} />
                <div className="row">
                    <div className="col-md">
                        <FormInput model={this.props.item} label="City" name="city" type="text" required={this.props.required} onChange={this.props.onChange} labelAsPlaceholder={this.props.labelAsPlaceholder} disabled={this.props.disabled} maxLength={20} />
                    </div>
                    <div className="col-md">
                        <FormInput model={this.props.item} label="Country" name="country" type="text" required={this.props.required} onChange={this.onCountryChange} options={countries.list} labelAsPlaceholder={this.props.labelAsPlaceholder} disabled={this.props.disabled} maxLength={10} />
                    </div>
                    <div className="col-md">
                        <FormInput model={this.props.item} label="Postal Code" name="postal_code" type="text" required={this.props.required} onChange={this.props.onChange} labelAsPlaceholder={this.props.labelAsPlaceholder} disabled={this.props.disabled} maxLength={10} />
                    </div>
                </div> 
            </Card>
           );
    }
}