import BaseCom from "./BaseCom";
import FormInput from "./FormInput";
import { Preferences } from '@capacitor/preferences';
import './InstancePrompt.css';

export default class InstancePrompt extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {
                instance: ''
            }
        };
    }

    buildUrl(ident) {
        return 'https://' + ident + '.salesconsole.app';
    }

    
    async componentDidMount() {
        var instance = await Preferences.get({ key: 'instance' });
        if(instance.value) {
            console.log('Loading instance', instance.value);
            this.props.onComplete(this.buildUrl(instance.value));
            return;
        }
    }

    change = () => {
        this.setState({ info: this.state.info });
    }

    submit = async (e) => {
        e.preventDefault();
        await Preferences.set({ key: 'instance', value: this.state.info.instance });
        this.props.onComplete(this.buildUrl(this.state.info.instance));
    }

    render() {
        if (!this.props.show)
            return null;
        return <div className="instance-prompt">
            <div className="container">
                <form onSubmit={this.submit}>
                    <h1 className="text-center">Instance</h1>
                    <p className="text-center text-muted">Please enter the name of the instance you would like to access.</p>
                    <FormInput model={this.state.info} type="text" name="instance" label="Instance Name" onChange={this.change} />
                    <div className="d-grid">
                        <button type="submit" className="btn btn-primary">Go</button>
                    </div>
                </form>
            </div>
        </div>;
    }
}