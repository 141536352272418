import BaseCom from "./BaseCom";

export default class AppFloatingActions extends BaseCom {
    render() {
        return (
            <div className="app-actions app-actions-form">
                {this.props.children}
            </div>
        );
    }
}