import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import Alert from "./Alert";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import FormLabel from "./FormLabel";
import Icon from "./Icon";
import L from "./Lang";
import LoadingAlert from "./LoadingAlert";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class VisitStart extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            item: {},
            info: {
                motiveSales: true
            }
        };
        this.submit = this.submit.bind(this);
        this.submitVisit = this.submitVisit.bind(this);
        this.pullShippingList = this.pullShippingList.bind(this);
        this.checkTax = this.checkTax.bind(this);
    }

    componentDidMount() {
        NavMenuGlobal.setPath('Visits,/console/visit;Start');
        if (this.props.params.id) {
            qreq.get('/api/visit/item?id=' + this.props.params.id, j => {
                if (j.item) {
                    if (j.item.isStarted)
                        this.props.navigate('/console/visit/' + j.item.id + '/order');
                    else {
                        this.setState({ item: j.item, info: { ...j.item } });
                        this.checkTax(j.item.customerID, j.item.customerShippingID);
                    }
                }
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback);
        }
        else {
            qreq.get('/api/customer/list', j => {
                if (j.list)
                    this.setState({ customers: j.list });
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback);
            this.setState({ item: {} });
        }
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }


    pullShippingList(id) {
        this.setState({ ships: [], shipsLoading: true });
        qreq.get('/api/customer/shipping/list?custID=' + id, j => {
            if (j.list) {
                this.setState({ ships: j.list });
                if(j.list.length === 1)
                    this.setState({ info: {...this.state.info, customerShippingID: j.list[0].id }});
            }
            else
                this.alert(j.errorMessage);
            this.setState({ shipsLoading: false });
        }, () => {
            this.unkownErrorCallback();
            this.setState({ shipsLoading: false });
        });
    }

    checkTax(custID, shipID) {
        qreq.get('/api/customer/tax/check?custID=' + custID + '&shipID=' + shipID, j => {
            if(j.errorCode === 6)
                this.setState({ taxInfo: { errorMessage: 'Tax information not found.' }});
            else if(j.item)
                this.setState({ taxInfo: {...j.item, errorMessage: j.item.isExpired ? 'Tax information is expired.' : null}});
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
        this.setState({ info: { ...this.state.info } })
    }

    submit(e, item) {
        if (e) e.preventDefault();
        var info;
        if(item)
            info = {...item};
        else
            info = { ...this.state.info };
        if (info.id) {
            qreq.post('/api/visit/start?dt=' + Globals.getLocalDateString(), info, j => {
                if (j.errorCode)
                    this.alert(j.errorMessage);
                else {
                    this.success(<L>Visit has been started.</L>);
                    this.props.navigate('/console/visit/' + info.id + '/order');
                }
            }, this.unkownErrorCallback);
        }
        else {
            this.submitVisit(null, item => this.submit(null, item));
        }
    }

    submitVisit(e, callback) {
        if (e) e.preventDefault();
        var info = { ...this.state.item, ...this.state.info };
        if (!info.plan_date)
            info.plan_date = Globals.getLocalDateString();
        qreq.post('/api/visit/save', info, j => {
            if(j.errorCode === 9) {
                //this.success(<L>Using planned visit.</L>);
                if (callback)
                    callback(j.item);
            }
            else if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                //this.success(<L>Visit has been added.</L>);
                if (callback)
                    callback(j.item);
            }
        }, this.unkownErrorCallback);
    }

    render() {
        return <div className="container-fluid">
            {this.state.item ?
                <form onSubmit={this.submit}>
                    {this.state.item.id ? <Card title="Visit">
                        <FormLabel value={this.state.item.customerName} label="Customer" />
                        <FormLabel value={this.state.item.customerShippingName} label="Shipping" />
                        {this.state.taxInfo && this.state.taxInfo.errorMessage ? <Alert type="warning">{this.state.taxInfo.errorMessage}</Alert> : null}
                    </Card> : <Card title="Visit">
                        {this.state.customers ? <FormInput model={this.state.info} name="customerID" label="Customer" type="select-search" options={this.state.customers} onChange={this.pullShippingList} required /> : <LoadingAlert />}
                        {this.state.shipsLoading ? <LoadingAlert /> : this.state.ships ? <FormInput model={this.state.info} name="customerShippingID" label="Shipping" type="select" options={this.state.ships} required onChange={v => this.checkTax(this.state.info.customerID, v)} /> : null}
                        {this.state.taxInfo && this.state.taxInfo.errorMessage ? <Alert type="warning">{this.state.taxInfo.errorMessage}</Alert> : null}
                    </Card>}
                    <Card title="Reason">
                        <FormInput model={this.state.info} type="switch" name="motiveSales" label="Sales" />
                        <FormInput model={this.state.info} type="switch" name="motivePromotion" label="Promotion" />
                        <FormInput model={this.state.info} type="switch" name="motiveServices" label="Services" />
                        <FormInput model={this.state.info} type="switch" name="motiveShopper" label="Shopper" />
                    </Card>
                    <Card title="Message">
                        <FormInput model={this.state.info} type="textarea" name="message" label="Message" maxLength={160} />
                    </Card>
                    <div className="my-3 d-flex gap-1 app-form-actions">
                        <div>
                            <button type="button" className="btn btn-secondary btn-lg" onClick={this.goBack}><Icon icon="faArrowLeft" /> <L>Back</L></button>
                        </div>
                        <div className="flex-fill text-end">
                            <button type="submit" className="btn btn-primary btn-lg"><Icon icon="faTruckFast" /> <L>Start</L></button>
                        </div>
                    </div>
                </form> : <LoadingAlert />}
        </div>;
    }
}

export default withParamsAndNavigate(VisitStart);