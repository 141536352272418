import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Icon from "./Icon";
import L from "./Lang";
import './PrintView.css';
import Globals from "../shared/Globals";
import { registerPlugin } from '@capacitor/core';
import React from "react";

const ARMPrint = registerPlugin('ARMPrint');

export const PrintGlobal = {
    show() {},
    hide() {},
    print(data) {
        window.print();
    }
};


document.addEventListener('deviceready', function () {
    PrintGlobal.print = (data) => {
        window.cordova.plugins.printer.print(data);
    }
}, false);

export class PrintView extends BaseCom {

    constructor(props) {
        super(props);
        this.state = { show: false, src: null };
        PrintGlobal.show = this.show;
        PrintGlobal.hide = this.hide;
        this.iframeRef = React.createRef();
    }

    show = (src) => {
        if(Globals.isDeviceApp()) {
            if(src.indexOf('?') >= 0)
                src += '&';
            else
                src += '?';
            src += 'isDevice=true';
        }
        this.setState({ show: true, originalSrc: src, isDevice: Globals.isDeviceApp() });
        this.getToken(src, (url) => {
            this.getContent(url);
        });
    }

    getToken = (src, callback) => {
        qreq.post('/api/token/request', {}, j => {
            if(j.item) {
                if(src.indexOf('?') > 0)
                    src += '&';
                else
                    src += '?';
                src += 'token=' + j.item;       
                this.setState({ token: j.item, src: src });
                callback?.(src);
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    getContent = (src) => {
        fetch(src, {
            method: 'GET'
        }).then(async (r) => {
            this.setState({ content: encodeURIComponent(await r.text()) });   
        }, (e) => {
            this.unkownErrorCallback();
        });
    }

    hide = () => {
        this.setState({ show: false, token: null, src: null });
    }

    print = async () => {
        if(Globals.isDeviceApp()) {
            this.getToken(this.state.originalSrc, async (url) => {
                await ARMPrint.print({ url: url });
            });
        }
        else {
            window.print();
        }
    }

    render() {
        if(!this.state.show)
            return null;
        return <div className="print-view">
            {this.state.content && <iframe ref={this.iframeRef} id="print-iframe" name="print-iframe" src={'data:text/html;charset=utf-8,' + this.state.content} title="Print View" className="print-content" width="100%" border="0" />}
            <div className="print-view-footer d-flex justify-content-between d-print-none">
                <button type="button" className="btn btn-primary btn-lg" onClick={() => this.hide()}><L>Close</L></button>
                {this.state.isDevice && <button type="button" className="btn btn-primary btn-lg" onClick={() => this.print()} disabled={!this.state.token}><Icon icon="faPrint" /> <L>Print</L></button>}
            </div>
        </div>;
    }
}