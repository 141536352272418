import { useNavigate, useParams } from "react-router-dom";
import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import DataTable from "./DataTable";
import L from "./Lang";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import Icon from "./Icon";
import SalesVsQuoteChart from "./chart/SalesVsQuoteChart";

export class SaleQuoteReport extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount() {
        qreq.get('/api/sale/quote/summary', j => {
            if (j.list) {
                this.setState({ data: j.list });
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }


    render() {
        if (!this.state.showDetail)
            return <div className="container-fluid">
                <SalesVsQuoteChart />
                <div className="mt-3 text-center">
                    <button className="btn btn-primary btn-lg" onClick={() => this.setState({ showDetail: true })}><Icon icon="faTable" /> <L>View Detail</L></button>
                </div>
                <div className="app-actions">
                    <button className="btn btn-primary btn-lg" onClick={() => this.setState({ showDetail: true })}><Icon icon="faTable" /></button>
                </div>
            </div>
        return <div className="container-fluid">
            <div className="app-form-actions">
                <button className="btn btn-primary btn-lg" onClick={() => this.setState({ showDetail: false })}><Icon icon="faArrowLeft" /> <L>Go Back</L></button>
            </div>
            <DataTable columns={[
                {
                    name: 'slmn_name', title: 'Salesman'
                },
                {
                    name: 'quote', title: 'Quote', format: 'currency'
                },
                {
                    name: 'invoiced', title: 'Invoice', format: 'currency'
                },
                {
                    name: 'dif_1', title: 'Dif', format: 'currency'
                },
                {
                    name: 'porc_1', title: '%', valueFunc: row => { return row.porc_1 + '%' }
                },
                {
                    name: 'ord_pend', title: 'Pending', format: 'currency'
                },
                {
                    name: 'invoice_ord', title: 'Projected', format: 'currency'
                },
                {
                    name: 'dif_2', title: 'Dif', format: 'currency'
                },
                {
                    name: 'porc_2', title: '%', valueFunc: row => { return row.porc_2 + '%' }
                },
                {
                    name: 'pend_by_day', title: 'PBD', format: 'currency'
                },
                {
                    name: 'dt', title: 'Date', valueFunc: row => {
                        return row.period + '/' + row.year;
                    }
                },
                {
                    name: 'pend_day', title: 'Pend. Day', valueFunc: row => {
                        return row.pend_day + ' days';
                    }
                }
            ]} data={this.state.data} />
            <div className="app-actions d-block d-md-none">
                <small><Icon icon="faHandPointer" /> Swipe left or right to see more data.</small>
            </div>
        </div>;
    }
}

class SaleQuote extends BaseCom {

    componentDidMount() {
        NavMenuGlobal.setPath('Sale Quote');
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    render() {
        return <SaleQuoteReport />;
    }
}

export function SaleQuoteReportWithParams() {
    return <SaleQuoteReport params={useParams()} navigate={useNavigate()} />;
}

export default withParamsAndNavigate(SaleQuote);