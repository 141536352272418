import BaseCom from "./BaseCom";
import Icon from "./Icon";
import L from "./Lang";

export default class AppFormActions extends BaseCom {
    render() {
        return <div className="my-3 d-flex gap-1 app-form-actions">
        {this.props.showBackButton && <div>
            <button type="button" className="btn btn-secondary btn-lg" onClick={this.goBack}><Icon icon="faArrowLeft" /> <L>Back</L></button>
        </div>}
        <div className="flex-fill text-end">
            {this.props.children}
        </div>
    </div>;
    }
}