import { Modal } from "react-bootstrap";
import BaseCom from "./BaseCom";
import FormInput from "./FormInput";
import L from "./Lang";
import Icon from "./Icon";
import { qreq } from "../shared/qrequest";
import Globals from "../shared/Globals";
import React from "react";

export default class TaxUpdateModal extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {
                tax_expired: Globals.toISODateString(new Date())
            }
        };
        this.uploadRef = React.createRef();
        this.formRef = React.createRef();
        this.submit = this.submit.bind(this);
        this.upload = this.upload.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.show !== prevProps.show && this.props.show) {
            this.setState({ info: {} });
            qreq.get('/api/customer/tax/check?custID=' + this.props.custID + '&shipID=' + this.props.shipID, j => {
                if (j.item)
                    this.setState({ info: {...j.item, tax_expired: Globals.toISODateString(new Date())} });
            });
        }
    }

    submit(e, fileIdent) {
        if (e) e.preventDefault();
        qreq.post('/api/customer/tax/update', {...this.state.info, customerID: this.props.custID, customerShippingID: this.props.shipID, imageIdent: fileIdent }, j => {
            if(j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success('Tax information has been updated.');
                this.props.onHide();
            }
        }, this.unkownErrorCallback);
    }

    upload() {
        if(!this.uploadRef.current)
            return;
        if(this.uploadRef.current.files.length === 0) {
            this.alert('Please select a file to upload.');
            return;
        }
        let file = this.uploadRef.current.files[0];
        let formData = new FormData();
        formData.append('file', file);
        this.uploadRef.current.value = null;
        if(!this.state.info.tax_references) {
            this.alert('Please enter a reference ID before uploading.');
            return;
        }
        this.setState({ submitting: true });
        qreq.postfd('/api/storage/upload', formData, j => {
            if(j.errorCode)
                this.alert(j.errorMessage);
            else {
                console.log(j.item.ident);
                this.submit(null, j.item.ident);
            }
            this.setState({ submitting: false });
        }, () => {
            this.unkownErrorCallback();
            this.setState({ submitting: false });
        });
    }

    render() {
        return <Modal show={this.props.show} onHide={this.props.onHide}>
            <Modal.Header closeButton>
                Tax Information
            </Modal.Header>
            <form onSubmit={this.submit} ref={this.formRef}>
                <Modal.Body>
                    <input ref={this.uploadRef} type="file" capture="camera" accept="image/*" style={{ display: 'none' }} onChange={this.upload} />
                    <FormInput model={this.state.info} name="tax_references" label="Reference ID" type="text" required onChange={() => this.setState({ info: {...this.state.info}})} />
                    <FormInput model={this.state.info} name="tax_expired" label="Expires" type="date" required />
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-success" onClick={() => this.uploadRef.current?.click()} disabled={this.state.submitting}><Icon icon="faUpload" /> <L>Upload and Save</L></button>
                    <button type="submit" className="btn btn-primary" disabled={this.state.submitting}><Icon icon="faSave" /> <L>Save</L></button>
                    <button type="button" className="btn btn-secondary" onClick={this.props.onHide} disabled={this.state.submitting}><Icon icon="faCancel" /> <L>Cancel</L></button>
                </Modal.Footer>
            </form>
        </Modal>;
    }
}