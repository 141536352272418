import React from 'react';
import BaseComponent from '../components/BaseCom';
import FormInput from '../components/FormInput';
import { withParamsAndNavigate } from '../components/WithParamsAndNavigate';
import { qreq } from '../shared/qrequest';
import Globals from '../shared/Globals';
import Auth from '../shared/Auth';
import { Link } from 'react-router-dom';
import Card from './Card';
import './Login.css';
import L from './Lang';

class Login extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            org: {},
            credentials: {},
            submitting: false
        };
        this.submit = this.submit.bind(this);
        this.setUser = props.setUser;
        this.loadOrg = this.loadOrg.bind(this);
    }

    componentDidMount() {
        this.loadOrg();
    }

    componentDidUpdate(prevProps) {
        if(this.props.serverUrl !== prevProps.serverUrl) {
            this.loadOrg();
        }
    }

    loadOrg() {
        qreq.get('/api/org/info/public', j => {
            if (j.item) {
                this.setState({ org: j.item });
                Auth.getLogin(u => {
                    if (u)
                        this.props.navigate('/console/dashboard');
                    else {
                        this.setState({ loaded: true });
                    }
                });
            }
            else if (j.errorCode === 1004) {
                this.setState({ instanceError: j.errorCode, loaded: true });
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    submit(e) {
        e.preventDefault();
        this.setState({ submitting: true });
        qreq.post('/api/auth/login', this.state.credentials, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else if (j.item) {
                Globals.set('user', j.item);
                this.props.navigate('/console/dashboard');
            }
            this.setState({ submitting: false });
        }, e => {
            this.alert('An unkown error ocurred. Login service might be down.');
            this.setState({ submitting: false });
        });
    }

    render() {
        if (!this.state.loaded)
            return <></>;
        if (this.state.instanceError)
            return <div className="login">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="p-4">
                                <Card title="Instance Not Found">
                                    <p><L>The sales console instance you are trying to access does not exist.</L></p>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;

        return <div className="login">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="p-4">
                            {this.state.org.logoURL ? <img src={Globals.serverUrl + this.state.org.logoURL} alt="" className="img-fluid mx-auto d-block" /> : <h1 className="text-center mb-3">{this.state.org.name}</h1>}
                            <form onSubmit={this.submit}>
                                <Card title="Login">
                                    <FormInput model={this.state.credentials} name="email" label="Email Address" type="text" required={true} labelAsPlaceholder={true} autoComplete="username" />
                                    <FormInput model={this.state.credentials} name="password" label="Password" type="password" required={true} labelAsPlaceholder={true} autoComplete="current-password" />
                                    <div className="d-grid mt-4">
                                        <button type="submit" className="btn btn-primary btn-lg" disabled={this.state.submitting}><L>Login</L></button>
                                    </div>
                                    <div className="my-3 text-center"><Link to="/forgot-password"><L>I forgot my password</L></Link></div>
                                    {this.state.error ? (<div className="alert alert-danger mt-2">{Globals.getLang(this.state.error)}</div>) : ''}
                                </Card>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withParamsAndNavigate(Login);