import BaseCom from "./BaseCom";
import Icon from "./Icon";
import L from "./Lang";

export default class Info extends BaseCom {
    render() {
        return <div className={'d-flex align-items-center mb-3' + (this.props.className ? ' ' + this.props.className : '')}>
            <Icon icon="faInfoCircle" className="d-block me-2 text-secondary" />
            <div className="d-block">{this.props.children}</div>
        </div>;
    }
}