import BaseCom from "./BaseCom";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import L from "./Lang";

class YTD extends BaseCom {

    componentDidMount() {
        if(this.props.params.visitID)
            NavMenuGlobal.setPath('Visits,/console/visit;Order,/console/visit/' + this.props.params.visitID + '/order;YTD');
        else
            NavMenuGlobal.setPath('Customer,/console/customer;Customer,/console/customer/' + this.props.params.id + ';YTD');
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    render() {
        return <div className="container-fluid">
            <h1><L>Under Construction</L></h1>
        </div>;
    }
}

export default withParamsAndNavigate(YTD);