import { Alert } from "react-bootstrap";
import BaseCom from "./BaseCom";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import L from "./Lang";
import { qreq } from "../shared/qrequest";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import { NavMenuGlobal } from "./NavMenu";
import Globals from "../shared/Globals";
import GestureElement from "./GestureElement";
import { Pop } from "./PopModal";
import Info from "./Info";
import Auth from "../shared/Auth";

class Visit extends BaseCom {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.delete = this.delete.bind(this);
        this.clearActive = this.clearActive.bind(this);
    }

    componentDidMount() {
        Auth.getLogin(u => {
            this.setState({ user: u });
        });
        this.load();
        NavMenuGlobal.setPath('Visits');
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    load() {
        qreq.get('/api/visit/list?dt=' + Globals.getLocalDateString(), j => {
            if (j.list)
                this.setState({ list: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    delete(item) {
        Pop.showConfirm(<><L>Are you sure you want to delete:</L> {item.customerName} {item.customerShippingName} on {item.plan_date}</>, 'Delete', () => {
            qreq.post('/api/visit/delete', { ...item }, j => {
                if (j.errorCode)
                    this.alert(j.errorMessage);
                else
                    this.success('Visit deleted.');
                this.load();
            }, this.unkownErrorCallback);
        });
    }

    clearActive() {
        if (!this.state.list)
            return;
        var list = [...this.state.list];
        list.forEach(i => {
            i.active = false;
        });
        this.setState({ list: list });
    }

    render() {
        return <div className="container-fluid" onClick={() => this.clearActive()}>
            {this.state.user?.canWrite && <>
                <div className="app-actions">
                    <Link to="/console/visit/new" className="btn btn-primary btn-lg"><Icon icon="faCalendar" /></Link>
                    <Link to="/console/visit/start" className="btn btn-primary btn-lg"><Icon icon="faTruckFast" /></Link>
                </div>
                <div className="d-none d-md-flex mb-3 gap-1">
                    <Link to="/console/visit/new" className="btn btn-primary"><Icon icon="faCalendar" /> <L>Schedule Visit</L></Link>
                    <Link to="/console/visit/start" className="btn btn-primary"><Icon icon="faTruckFast" /> <L>Start Visit</L></Link>
                </div>
            </>}
            <Info><L>You can also start/edit a visit by clicking an existing one on the list below.</L></Info>
            {this.state.list ? this.state.list.length === 0 ? <Alert><L>There are no visits.</L></Alert> : <div className="list-group">
                {this.state.list.map(i => <GestureElement key={i.id} className="list-group-item d-flex align-items-center"
                    onSwipeRight={() => { i.active = true; this.setState({ list: [...this.state.list] }); }}
                    onSwipeLeft={() => { i.active = false; this.setState({ list: [...this.state.list] }); }}
                >
                    <Link to={'/console/visit/' + i.id + '/start'} className="d-block flex-fill">
                        <div className="text-primary fs-5">{i.customerName}</div>
                        <div className="text-secondary">{i.customerShippingName}</div>
                        <div className="text-muted">{Globals.toDateDeltaDisplay(i.plan_date)}</div>
                        {i.isStarted ? <div className="text-success"><L>Started</L></div> : null}
                        {this.state.user?.isSupervisor && <div className="small text-super"><L>Salesman:</L> {i.createdByUserName}</div>}
                    </Link>
                    <div className={i.active ? 'd-block' : 'd-none d-md-block'}>
                        <button type="button" className="btn btn-danger btn-lg" onClick={() => this.delete(i)}><Icon icon="faTrash" /></button>
                    </div>
                </GestureElement>)}
            </div> : <LoadingAlert />}
        </div>;
    }
}

export default withParamsAndNavigate(Visit);