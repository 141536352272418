import BaseCom from "./BaseCom";

class GestureElement extends BaseCom {
    thresh = 5;
    xDown = null;
    yDown = null;

    constructor(props) {
        super(props);
        this.getTouches = this.getTouches.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
    }

    getTouches(evt) {
        return evt.touches || evt.originalEvent.touches; // Browser API or jQuery
    }

    handleTouchStart(evt) {
        const firstTouch = this.getTouches(evt)[0];
        this.xDown = firstTouch.clientX;
        this.yDown = firstTouch.clientY;
    }

    handleTouchMove(evt) {
        if (!this.xDown || !this.yDown) return;

        const xUp = evt.touches[0].clientX;
        const yUp = evt.touches[0].clientY;

        const xDiff = this.xDown - xUp;
        const yDiff = this.yDown - yUp;


        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > this.thresh) {
                if(this.props.onSwipeRight) this.props.onSwipeRight();
            } else if(xDiff < -this.thresh) {
                if(this.props.onSwipeLeft) this.props.onSwipeLeft();
            }
        } else {
            if (yDiff > this.thresh) {
                if(this.props.onSwipeDown) this.props.onSwipeDown();
            } else if(yDiff < -this.thresh) {
                if(this.props.onSwipeUp) this.props.onSwipeUp();
            }
        }

        // Reset values
        this.xDown = null;
        this.yDown = null;
    }

    render() {
        return <div onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove} className={this.props.className} style={this.props.style}>
            {this.props.children}
        </div>;
    }
}

export default GestureElement;