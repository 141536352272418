import React, { Component } from 'react';
import { Dropdown, Navbar } from 'react-bootstrap';
import { Link, NavLink as DomNavLink } from 'react-router-dom';
import './NavMenu.css';
import Auth from '../shared/Auth';
import Icon from './Icon';
import L from './Lang';
import GestureDetect from './GestureDetect';
import { withParamsAndNavigate } from './WithParamsAndNavigate';
import Globals from '../shared/Globals';
import BaseCom from './BaseCom';
import { AppGlobal } from '../App';
import { getLanguages } from '../shared/GLang';

export const NavMenuGlobal = {
  setTitle(title) { },
  setPath(path) { },
  clearTitle() { }
}

class _NavMenu extends BaseCom {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      title: null,
      nav: []
    };
    this.setTitle = this.setTitle.bind(this);
    this.setPath = this.setPath.bind(this);
    this.clearTitle = this.clearTitle.bind(this);
    NavMenuGlobal.setTitle = this.setTitle;
    NavMenuGlobal.setPath = this.setPath;
    NavMenuGlobal.clearTitle = this.clearTitle;

  }

  componentDidMount() {
    Auth.getLogin(u => this.setState({ user: u }));
    this.fetchNav();
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang !== prevProps.lang) {
      this.fetchNav();
    }
  }

  setTitle(title) {
    this.setState({ title: title, path: null });
  }

  clearTitle() {
    this.setState({ title: null, path: null });
  }

  setPath(path) {
    if (typeof (path) === 'string')
      path = path.split(';');
    for (var n = 0; n < path.length; n++) {
      if (typeof (path[n]) === 'string')
        path[n] = path[n].split(',');
    }
    this.setState({ path: path, title: null });
  }

  render() {
    return <>
      <GestureDetect onSwipeLeft={() => this.props.toggleNavbar(true)} onSwipeRight={() => this.props.toggleNavbar(false)} />
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 ps-3 d-flex">
          <div className="text-start d-none d-md-none">
            <div className="py-2 pe-2 me-2" type="button" onClick={() => this.props.toggleNavbar(!this.props.showNavbar)}><span className="navbar-toggler-icon"></span></div>
          </div>
          <div className="flex-fill d-flex align-items-center">
            <Navbar.Brand tag={Link} to="/">Console</Navbar.Brand>
            {this.state.title ? <div className="fs-5 text-primary">{Globals.getLang(this.state.title)}</div> : null}
            {this.state.path ? <div className="fs-5 text-primary d-flex">
              {this.state.path.map(i => <span key={i[0]} className="d-block me-2 app-nav-path" role={i.length >= 2 ? 'button' : null} onClick={i.length >= 2 ? () => this.props.navigate(i[1]) : null}>{Globals.getLang(i[0])}</span>)}
            </div> : null}
            <div className="flex-fill text-end pe-3">
              <Dropdown align="end">
                <Dropdown.Toggle className="text-uppercase">{this.props.lang}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {getLanguages().map(i => <Dropdown.Item key={i.ident} onClick={() => AppGlobal.setLang(i.ident)}>{i.name}</Dropdown.Item>)}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Navbar>
      </header>
      {this.state.user ? <><div className={'app-nav ' + (this.props.showNavbar ? 'hide' : 'hide') /*disabled*/}>
        <div className="float-end d-block d-md-none">
          <div className="p-3" type="button" onClick={() => this.props.toggleNavbar(!this.props.showNavbar)}><span className="navbar-toggler-icon navbar-toggler-icon-light"></span></div>
        </div>
        <div className="app-nav-body">
          <div className="app-nav-menu-title">
            <L>Menu</L>
          </div>
          <ul className="navbar-nav">
            <li className="nav-item">
              <DomNavLink to="/console/dashboard" end className="nav-link" onClick={() => this.props.toggleNavbar(false)}>
                <Icon icon="faHome" />
                <span><L>Dashboard</L></span>
              </DomNavLink>
            </li>
          </ul>
          <div className="app-nav-menu-title">
            <L>Daily</L>
          </div>
          <ul className="navbar-nav">
            {this.state.nav.filter(a => a[2] !== '/console/dashboard').map(i => <li key={i[0]} className="nav-item">
              <DomNavLink to={i[2]} end className="nav-link" onClick={() => this.props.toggleNavbar(false)}>
                <Icon icon={i[3]} />
                <span>{i[1]}</span>
              </DomNavLink>
            </li>)}
          </ul>
          <div className="app-nav-menu-title">
            <L>Session</L>
          </div>
          <ul className="navbar-nav">
            <li className="nav-item">
              <DomNavLink to="/logout" end className="nav-link" onClick={() => this.props.toggleNavbar(false)}>
                <Icon icon="faArrowRightFromBracket" />
                <span><L>Logout</L></span>
              </DomNavLink>
            </li>
          </ul>
        </div>
      </div>
        <div className={'app-nav-bg ' + (this.props.showNavbar ? 'hide' : 'hide') /*disabled*/} onClick={() => this.props.toggleNavbar(false)}></div>
        <div className="nav-mobile">
          {this.state.nav.map(i => i[4] ? <DomNavLink key={i[0]} to={i[2]} className="nav-mobile-item" onClick={() => this.props.toggleNavbar(false)}>
              <Icon icon={i[3]} />
              <span>{i[1]}</span>
            </DomNavLink> : null)}
            <DomNavLink to="/console/menu" className="nav-mobile-item" onClick={() => this.props.toggleNavbar(false)}>
              <Icon icon="faBars" />
              <span><L>Menu</L></span>
            </DomNavLink>
        </div>
      </> : ''}
    </>
      ;
  }
}

export const NavMenu = withParamsAndNavigate(_NavMenu);