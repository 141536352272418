import { Link } from "react-router-dom";
import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Icon from "./Icon";
import L from "./Lang";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import AppFormActions from "./AppFormActions";

class Statement extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        };
        this.exportPDF = this.exportPDF.bind(this);
    }

    componentDidMount() {
        if (this.props.params.visitID)
            NavMenuGlobal.setPath('Visits,/console/visit;Order,/console/visit/' + this.props.params.visitID + '/order;A/R');
        else
            NavMenuGlobal.setPath('A/R,/console/statement;Customer');
        qreq.get('/api/ar/list?custID=' + this.props.params.id, j => {
            if (j.list)
                this.setState({ list: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    exportPDF() {
        window.open(Globals.serverUrl + '/api/ar/export/render/pdf/' + this.props.params.id + '/ar-statement.pdf', '_blank');
    }



    render() {
        return <div className="container-fluid">
            <div className="app-actions d-none">
                <Link to="/" role="button" className="btn btn-primary btn-lg"><Icon icon="faMoneyBills" /></Link>
            </div>
            <div className="d-none d-md-block mb-3">
            </div>
            <ul className="list-group">
                {this.state.list.map(i => <div key={i.id} className="list-group-item" role="button" onClick={() => this.props.navigate(this.props.params.visitID ? '/console/visit/' + this.props.params.visitID + '/statement/' + this.props.params.id + '/' + i.id + '/pay' : '/console/statement/' + this.props.params.id + '/' + i.id + '/pay')}>
                    <div>{i.trans_num} <span className="text-secondary"><L>on</L> {Globals.toMDYDateString(i.trans_date)}</span></div>
                    <div className="d-flex"><div className="text-secondary flex-fill">Amount: {Globals.formatPrice(i.trans_amt)}</div> <strong className={'d-block text-end ' + (i.balance > 0 ? 'text-danger' : 'text-success')}>{Globals.formatPrice(i.balance)} Bal</strong></div>
                </div>)}
            </ul>
            <AppFormActions showBackButton>
                <button type="button" className="btn btn-primary btn-lg" onClick={this.exportPDF}>
                    <Icon icon="faFilePdf" />
                    <span className="d-none d-md-inline"> <L>Statement</L></span>
                </button>
            </AppFormActions>
        </div>;
    }
}

export default withParamsAndNavigate(Statement);