import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom"
import FormInput from "./FormInput";
import Icon from "./Icon";
import L from "./Lang";
import LoadingAlert from "./LoadingAlert";
import Tick from "./Tick";

export default class ItemCatalogCategory extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            query: {},
            list: []
        };
        this.load = this.load.bind(this);
    }
    
    componentDidMount() {
        this.load();
    }

    load(search) {
        qreq.get('/api/item/category/list' + (search ? '?search=' + search : ''), j => {
            if(j.list)
                this.setState({ list: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    render() {
        return <div className="container-fluid">
            {this.state.list ? <>
                <FormInput model={this.state.query} name="search" type="text" label="Search..." labelAsPlaceholder onChange={() => this.setState({ query: { ...this.state.query }, searching: true })} />
                <Tick onTick={() => {
                    if (this.state.searching && this.state.query.search !== this.state.search) {
                        this.setState({ searching: false });
                        this.load(this.state.query.search);
                    }
                }} /><div className="list-group list-group-sm">
                {this.state.list.map(i => <div className="list-group-item" role="button" onClick={() => this.props.onFilter(i)}>
                    {i.desc_1}
                </div>)}
            </div>
            </> : <LoadingAlert />}
            {!this.props.noBack ? <div className="my-3 d-flex gap-1 app-form-actions">
                <div>
                    <button type="button" className="btn btn-secondary btn-lg" onClick={this.props.onCancel}><Icon icon="faArrowLeft" /> <L>Back</L></button>
                </div>
            </div> : null}
        </div>;
    }
}