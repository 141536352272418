import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import Icon from "./Icon";
import L from "./Lang";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class Promo extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {
                customerID: null,
                customerGroupID: null
            },
            customerList: [],
            customerGroupList: []
        };
        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        NavMenuGlobal.setPath('Promo');
        qreq.get('/api/customer/list', j => {
            if (j.list)
                this.setState({ customerList: j.list });
        });
        qreq.get('/api/customer/group/list', j => {
            if (j.list)
                this.setState({ customerGroupList: j.list });
        });
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    onChange(v) {

    }

    submit(e) {
        e.preventDefault();
        var info = {...this.state.info};
        if(!info.customerGroupID)
            info.customerGroupID = '0';
        if(!info.customerID)
            info.customerID = '0';
        if(!info.categoryID)
            info.categoryID = '0';
        window.open(Globals.serverUrl + '/api/report/promo/' + info.customerGroupID + '/' + info.customerID + '/0/promo.pdf', '_blank');
    }

    render() {
        return <div className="container-fluid">
            <form onSubmit={this.submit}>
                <Card title="Print Promotions">
                    <div className="row">
                        <div className="col-md">
                            <FormInput model={this.state.info} name="customerID" label="Customer" type="select" options={this.state.customerList} onChange={this.onChange} />
                        </div>
                        <div className="col-md">
                            <FormInput model={this.state.info} name="customerGroupID" label="Group" type="select" options={this.state.customerGroupList} onChange={this.onChange} />
                        </div>
                    </div>
                </Card>
                <div className="app-form-actions d-flex">
                    <div className="flex-fill text-end">
                        <button type="submit" className="btn btn-primary btn-lg"><Icon icon="faFilePdf" />  <L>Print</L></button>
                    </div>
                </div>
            </form>
        </div>;
    }
}

export default withParamsAndNavigate(Promo);