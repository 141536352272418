import React from "react";
import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Icon from "./Icon";
import L from "./Lang";
import LoadingAlert from "./LoadingAlert";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import { PrintGlobal } from "./PrintView";

class OrderDone extends BaseCom {

    constructor(props) {
        super(props);
        this.iframeRef = React.createRef();
        this.print = this.print.bind(this);
    }

    componentDidMount() {
        NavMenuGlobal.setPath('Visits,/console/visit;Order');
        qreq.get('/api/visit/item?id=' + this.props.params.id, j => {
            if (j.item) {
                this.setState({ visit: j.item });
                this.loadOrder();
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    loadOrder() {
        qreq.get('/api/order/item?id=' + this.props.params.orderID + '&saleType=' + (this.props.pre ? '1' : '2'), j => {
            if (j.item)
                this.setState({ order: j.item, items: j.item.items });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    print() {
        this.iframeRef.current?.contentWindow.print();
    }

    render() {
        return <div className="container-fluid">
            <h1 className="text-center"><L>Order Signed</L></h1>
            <h2 className="text-center"><L>Order #</L>: {this.state.order?.orderIdent}</h2>
            <div className="d-flex gap-1 app-form-actions">
                <div>
                    <button type="button" className="btn btn-secondary btn-lg" onClick={() => this.props.navigate('/console/visit/' + this.state.visit?.id + '/order')}><Icon icon="faArrowLeft" /> <L>Exit</L></button>
                </div>
                <div className="flex-fill">
                    <div className="d-flex gap-1 justify-content-end">
                        <button type="button" className="btn btn-lg btn-primary" onClick={() => PrintGlobal.show(Globals.serverUrl + '/api/receipt/sale/html/' + this.state.order?.saleType + '/' + this.state.order?.id + '/' + this.state.order?.user_num + '-' + this.state.order?.ord_id + '-' + this.state.order?.ord_seq + '.html')}><Icon icon="faReceipt" /> <span className="d-none d-md-inline"><L>Print Receipt</L></span></button>
                        <button type="button" className="btn btn-lg btn-primary" onClick={() => window.open(Globals.serverUrl + '/api/report/purchase-order/pdf/' + this.state.order?.saleType + '/' + this.state.order?.id + '/' + this.state.order?.user_num + '-' + this.state.order?.ord_id + '-' + this.state.order?.ord_seq + '.pdf')}><Icon icon="faFilePdf" /> <span className="d-none d-md-inline"><L>Download PDF</L></span></button>
                        <button type="button" className="btn btn-lg btn-primary d-none" onClick={() => this.print()}><Icon icon="faPrint" /> <L>Print Order</L></button>
                    </div>
                </div>
            </div>
            {!this.state.reportLoaded ? <LoadingAlert /> : null}
            {this.state.order ?
                <img ref={this.iframeRef} onLoad={() => this.setState({ reportLoaded: true })} title="report"  src={Globals.serverUrl + '/api/report/purchase-order/png/' + this.state.order?.saleType + '/' + this.state.order?.id + '/' + this.state.order?.user_num + '-' + this.state.order?.ord_id + '-' + this.state.order?.ord_seq + '.png'} className="w-100" alt="" />
                : null}
        </div>;
    }
}

export default withParamsAndNavigate(OrderDone);