import Globals from "../../shared/Globals";
import { qreq } from "../../shared/qrequest";
import BaseCom from "../BaseCom"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, AreaChart, BarChart, Bar } from 'recharts';
import L from "../Lang";

export default class SalesByMonthChart extends BaseCom {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    if (!this.props.useExternalData) {
      qreq.get('/api/chart/sales-by-month?dt=' + Globals.getLocalDateString(), j => {
        if (j.list)
          this.setState({ data: j.list });
      });
    }
    else
      this.setState({ data: this.props.data });
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ data: this.props.data });
    }
  }

  render() {
    if (!this.state.data)
      return null;
    return <>
      <h1 className="text-center text-secondary"><L>Sales/Collected By Month</L></h1>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          width={500}
          height={300}
          data={this.state.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="SalesByMonthChartColorDx" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#828aca" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#828aca" stopOpacity={0.5} />
            </linearGradient>
            <linearGradient id="SalesByMonthChartColorEdx" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={a => a ? <div className="bg-light border border-dark p-3">
            <div style={{ color: '#828aca' }}><L>Sales</L> {a.payload && a.payload.length > 0 ? Globals.formatPrice(a.payload[0].value) : null}</div>
            <div style={{ color: '#82ca9d' }}><L>Collections</L> {a.payload && a.payload.length > 1 ? Globals.formatPrice(a.payload[1].value) : null}</div>
          </div> : null} />
          <Legend payload={[{ value: <L>Sales</L>, type: 'bar', id: 'ID01', color: '#828aca' }, { value: <L>Collections</L>, type: 'bar', id: 'ID02', color: '#82ca9d' }]} />
          <Bar type="monotone" dataKey="dx" stroke="#828aca" fillOpacity={1} fill="url(#SalesByMonthChartColorDx)" />
          <Bar type="monotone" dataKey="edx" stroke="#82ca9d" fillOpacity={1} fill="url(#SalesByMonthChartColorEdx)" />
        </BarChart>
      </ResponsiveContainer>
    </>;
  }
}