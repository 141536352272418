import { Link } from "react-router-dom";
import BaseCom from "./BaseCom";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import L from "./Lang";
import { NavMenuGlobal } from "./NavMenu";
import SalesByDayChart from "./chart/SalesByDayChart";
import SalesByMonthChart from "./chart/SalesByMonthChart";
import Card from "./Card";
import DataTable from "./DataTable";
import { qreq } from "../shared/qrequest";
import Globals from "../shared/Globals";
import { EODReportWithParams } from "./EOD";
import Icon from "./Icon";

class Dashboard extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            orders: null,
            salesByDay: null,
            salesByMonth: null,
            totalSalesToday: null,
            total30Days: null,
            total12Months: null
        };
        this.pullChartData = this.pullChartData.bind(this);
    }
    componentDidMount() {
        NavMenuGlobal.setPath('Dashboard');
        qreq.get('/api/order/recent/list?dt=' + Globals.getLocalDateString(), j => {
            if (j.list)
                this.setState({ orders: j.list });
        });
        this.pullChartData();
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    pullChartData() {
        // Totals
        qreq.get('/api/chart/total-sales-today?dt=' + Globals.getLocalDateString(), j => {
            if (j.item) {
                this.setState({ totalSalesToday: j.item });
            }
        });
        qreq.get('/api/chart/total-ar-today?dt=' + Globals.getLocalDateString(), j => {
            if (j.item) {
                this.setState({ totalSalesToday: j.item });
            }
        });

        // Charts
        qreq.get('/api/chart/sales-by-day?dt=' + Globals.getLocalDateString(), j => {
            if (j.list) {
                var total = 0;
                j.list.forEach(i => total += i.dx);
                this.setState({ salesByDay: j.list, total30Days: total });
            }
        });
        qreq.get('/api/chart/sales-by-month?dt=' + Globals.getLocalDateString(), j => {
            if (j.list) {
                var total = 0;
                j.list.forEach(i => total += i.dx);
                this.setState({ salesByMonth: j.list, total12Months: total });
            }
        });
    }

    render() {
        console.log(this.state.salesByDay);
        return <div className="container-fluid">
            <div className="row">
                {this.state.totalSalesToday !== null && <div className="col">
                    <Card className="text-center">
                        <h1>{Globals.formatPrice(this.state.totalSalesToday)}</h1>
                        <div className="text-secondary"><L>Today</L></div>
                    </Card>
                </div>}
                {this.state.total30Days !== null && <div className="col">
                    <Card className="text-center">
                        <h1>{Globals.formatPrice(this.state.total30Days)}</h1>
                        <div className="text-secondary"><L>Last 30 days</L></div>
                    </Card>
                </div>}
                {this.state.total12Months !== null && <div className="col">
                    <Card className="text-center">
                        <h1>{Globals.formatPrice(this.state.total12Months)}</h1>
                        <div className="text-secondary"><L>Last 12 months</L></div>
                    </Card>
                </div>}
            </div>
            <div className="row">
                <div className="col-lg">
                    {this.state.salesByDay && <SalesByDayChart useExternalData data={this.state.salesByDay} arData={this.state.arByDay} />}
                </div>
                <div className="col-lg">
                    {this.state.salesByMonth && <SalesByMonthChart useExternalData data={this.state.salesByMonth} arData={this.state.arByMonth} />}
                </div>
            </div>
            {this.state.orders && <Card title="Your recent orders">
                <DataTable columns={[
                    {
                        name: 'status', title: ' ',
                        valueFunc: row => row.status === '1' ? <Icon icon="faBoxOpen" className="text-primary" /> : row.status === '2' ? <Icon icon="faPrint" className="text-success" /> : <Icon icon="faArrowUp" className="text-secondary" />,
                    },
                    {
                        name: 'name', title: 'Order ID', valueFunc: row => {
                            if (row.routePlanID)
                                return <Link to={'/console/visit/' + row.routePlanID + '/order/pre/' + row.id}>{row.name}</Link>;
                            return row.name;
                        }, className: 'd-none d-md-table-cell'
                    },
                    { name: 'customerName', title: 'Customer', valueFunc: row => <span style={{ fontSize: '1rem' }}>{row.customerName}</span> },
                    { name: 'orderType', title: 'Type', className: 'd-none d-md-table-cell' },
                    { name: 'order_date', title: 'Date', format: 'date' },
                    { name: 'ord_created', title: 'Sales Rep', className: 'd-none d-md-table-cell' },
                    { name: 'total', title: 'Sale', valueFunc: row => Globals.formatPrice(row.total)}
                ]} data={this.state.orders} />
            </Card>}
        </div>;
    }
}

export default withParamsAndNavigate(Dashboard);