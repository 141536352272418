import BaseCom from "./BaseCom";
import L from "./Lang";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class Collection extends BaseCom {

    componentDidMount() {
        NavMenuGlobal.setPath('Collection');
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    render() {
        return <div className="container-fluid">
            <h1><L>Under Construction</L></h1>
        </div>;
    }
}

export default withParamsAndNavigate(Collection);