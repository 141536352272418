import { Link } from "react-router-dom";
import Globals from "../shared/Globals";
import BaseCom from "./BaseCom";
import { v4 as uuidv4 } from 'uuid';
import sanitizeHtml from 'sanitize-html';
import { getLang } from "../shared/GLang";

export default class FormLabel extends BaseCom {

    constructor(props) {
        super(props);
        this.title = props.label;
        this.state = {
            id: uuidv4()
        };
        this.link = props.link;
        if (typeof (props.model) === 'object') {
            if (props.model instanceof Date) {
                this.state.value = Globals.toMDYDateString(props.model);
            }
        }
        else if (typeof (props.model) === 'string') {
            var regex = /\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d/g;
            if (regex.test(props.model)) {
                this.state.value = Globals.toMDYDateString(new Date(props.model));
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.model !== this.props.model) {
            this.setState({ value: this.props.model });
        }
    }

    nl2br(str) {
        if(str && str.replace)
            return this.sanitize(str.replace(/\n/g, '<br />'));
        return str;
    }

    sanitize(html) {
        const allowed = {
            allowedTags: ['br']
        };
        return sanitizeHtml(html, allowed);        
    }

    render() {
        this.tag = 'div';
        if(this.props.onClick)
            this.tag = 'a';
        return <div className="c-formlabel mb-3">
            <h5>{getLang(this.props.label)} {this.props.postLabel && <span>{this.props.postLabel}</span>}</h5>
            {this.props.textarea ? <div className="c-value" dangerouslySetInnerHTML={{ __html: this.nl2br(this.state.value) }}></div> :
            <this.tag className="d-block c-value" onClick={this.props.onClick} role={this.props.onClick ? 'button' : null}>{this.props.link ? <Link to={this.props.link}>{this.props.value ?? '(none)'}</Link> : (this.props.value ?? '(none)')}</this.tag>}
        </div>;
    }
}
