import { Link } from "react-router-dom";
import BaseCom from "./BaseCom";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import L from "./Lang";

class VisitOrderSelect extends BaseCom {
    render() {
        return <div className="container-fluid">
            <div className="list-group app-buttons-2">
                <div className="list-group-item">
                    <Link to="/console/visit/start/sale">
                        <img src="/assets/img/sale.png" alt="" className="img-fluid" />
                        <h2><L>Direct Sale</L></h2>
                    </Link>
                </div>
                <div className="list-group-item">
                    <Link to="/console/visit/start/purchase-order">
                        <img src="/assets/img/purchase-order.png" alt="" className="img-fluid" />
                        <h2><L>Purchase Order</L></h2>
                    </Link>
                </div>
            </div>
        </div>;
    }
}

export default withParamsAndNavigate(VisitOrderSelect);