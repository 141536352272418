import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import Icon from "./Icon";
import L from "./Lang";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class Catalog extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            category: null,
            info: {},
            categories: [],
            customers: [],
            custGroups: [],
            promos: []
        };
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        NavMenuGlobal.setPath('Catalog');
        qreq.get('/api/customer/list/contract', j => {
            if (j.list)
                this.setState({ customers: j.list });
        }, this.unkownErrorCallback);
        qreq.get('/api/customer/group/list/contract', j => {
            if (j.list)
                this.setState({ custGroups: j.list });
        });
        qreq.get('/api/item/category/list', j => {
            if (j.list)
                this.setState({ categories: j.list });
        });
        qreq.get('/api/promo/list', j => {
            if (j.list)
                this.setState({ promos: j.list });
        });
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    submit(e) {
        if (e) e.preventDefault();
        var info = { ...this.state.info };
        if (!info.customerGroupID)
            info.customerGroupID = '0';
        if (!info.customerID)
            info.customerID = '0';
        if (!info.categoryID)
            info.categoryID = '0';
        if (!info.promoID)
            info.promoID = '0';
        window.open(Globals.serverUrl + '/api/report/catalog/' + info.customerGroupID + '/' + info.customerID + '/' + info.categoryID + '/' + info.promoID + '/catalog.pdf', '_blank');
    }

    render() {
        if (!this.state.mode) {
            return <div className="container-fluid">
                <Card title="Catalog">
                    <div className="d-grid gap-1">
                        <button type="button" className="btn btn-primary btn-lg text-start" onClick={() => this.setState({ mode: 'category' })}><Icon icon="faList" style={{width: '25px'}} /> <L>By Category</L></button>
                        <button type="button" className="btn btn-primary btn-lg text-start" onClick={() => this.setState({ mode: 'customer' })}><Icon icon="faUser" style={{width: '25px'}} /> <L>By Customer</L></button>
                        <button type="button" className="btn btn-primary btn-lg text-start" onClick={() => this.setState({ mode: 'customer-group' })}><Icon icon="faUsers" style={{width: '25px'}} /> <L>By Customer Group</L></button>
                        <button type="button" className="btn btn-primary btn-lg text-start" onClick={() => this.setState({ mode: 'promo' })}><Icon icon="faGift" style={{width: '25px'}} /> <L>By Promotion</L></button>
                    </div>
                </Card>
            </div>;
        }
        return <div className="container-fluid">
            <form onSubmit={this.submit}>
                {this.state.mode === 'category' && <Card title="Catalog by Category">
                    <FormInput model={this.state.info} name="categoryID" type="select-search" label="Category" options={this.state.categories} onChange={() => this.setState({ info: {...this.state.info} })}  />
                </Card>}
                {this.state.mode === 'customer' && <Card title="Catalog by Customer">
                    <FormInput model={this.state.info} name="customerID" type="select-search" label="Customer" options={this.state.customers} onChange={() => this.setState({ info: {...this.state.info} })}  />
                </Card>}
                {this.state.mode === 'customer-group' && <Card title="Catalog by Customer Group">
                    <FormInput model={this.state.info} name="customerGroupID" type="select-search" label="Customer Group" options={this.state.custGroups} onChange={() => this.setState({ info: {...this.state.info} })} />
                </Card>}
                {this.state.mode === 'promo' && <Card title="Catalog by Promotion">
                    <FormInput model={this.state.info} name="promoID" type="select-search" label="Promotion" options={this.state.promos} onChange={() => this.setState({ info: {...this.state.info} })} />
                </Card>}
                <div className="d-flex gap-1 app-form-actions justify-content-end">
                    <div className="flex-fill">
                        <button type="button" className="btn btn-secondary btn-lg" onClick={() => this.setState({ mode: null, info: {} })}><Icon icon="faArrowLeft" /> <L>Back</L></button>
                    </div>
                    <div>
                        <button type="submit" className="btn btn-primary btn-lg"><Icon icon="faFilePdf" /> <L>Generate</L></button>
                    </div>
                </div>
            </form>
        </div>;
    }
}

export default withParamsAndNavigate(Catalog);