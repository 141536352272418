import { Outlet } from "react-router-dom";
import BaseCom from "./BaseCom";
import { NavMenu } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import ScrollToTop from './ScrollToTop';
import Auth from "../shared/Auth";
import Tick from "./Tick";
import { Pop } from "./PopModal";
import Globals from "../shared/Globals";

class ConsoleLayout extends BaseCom {
  constructor(props) {
    super(props);
    this.state = {
      showNavbar: false
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  componentDidMount() {
    Auth.getLogin(u => {
      this.setState({ user: u });
      if (!u)
        this.props.navigate('/login');
    });
  }

  tick = () => {
    Auth.getLogin(u => {
      if (!u)
        this.props.navigate('/login');
      else {
        if (!Globals.isDeviceApp()) {
          if (this.state.user.version && u.version && u.version !== this.state.user.version) {
            Pop.showConfirm(<>The portal version has changed from <b>{this.state.user.version}</b> to <b>{u.version}</b>. Please <i>Confirm</i> to refresh and view the newest version.</>, 'New Version', () => {
              window.location.reload(true);
            });
          }
        }
      }
      this.setState({ user: u });
    }, null, true);
  }

  toggleNavbar(on) {
    this.setState({ showNavbar: on });
  }

  render() {
    return <div className={this.state.showNavbar ? '' : 'app-navbar-hidden'}>
      <Tick onTick={this.tick} interval={10000} />
      <ScrollToTop />
      <NavMenu toggleNavbar={this.toggleNavbar} showNavbar={this.state.showNavbar} lang={this.props.lang} />
      <div className="app-body">
        <Outlet />
      </div>
    </div>;
  }
}

export default withParamsAndNavigate(ConsoleLayout);