import { Link } from "react-router-dom";
import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import AppFloatingActions from "./AppFloatingActions";
import AppFormActions from "./AppFormActions";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import FormLabel from "./FormLabel";
import Icon from "./Icon";
import L from "./Lang";
import { NavMenuGlobal } from "./NavMenu";
import { Pop } from "./PopModal";
import { PrintGlobal } from "./PrintView";
import Tick from "./Tick";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import Alert from "./Alert";

class StatementView extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            item: {
                payment: {
                    discount: 0,
                    collected: 0
                },
                header: {},
                discount: {}
            },
            bankList: []
        };
    }

    componentDidMount() {
        if (this.props.params.visitID)
            NavMenuGlobal.setPath('Visits,/console/visit;Order,/console/visit/' + this.props.params.visitID + '/order;A/R,/console/visit/' + this.props.params.visitID + '/statement/' + this.props.params.custID + ';View');
        else
            NavMenuGlobal.setPath('A/R,/console/statement;Customer,/console/statement/' + this.props.params.custID + ';View');
        this.load();
        this.loadCash();
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    load = () => {
        qreq.get('/api/ar/item?id=' + this.props.params.id, j => {
            if (j.item) {
                j.item.header.check_num = null;
                this.setState({ item: j.item });
                qreq.get('/api/ar/bank/list', j => {
                    if(j.list)
                        this.setState({ bankList: j.list });
                });
                
                qreq.get('/api/customer/item?custNum=' + j.item.cust_num, j => {
                    if(j.item)
                        this.setState({ customer: j.item });
                });
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    loadCash = () => {
        qreq.get('/api/ar/cash/list?arID=' + this.props.params.id, j => {
            if(j.list) {
                this.setState({ cashList: j.list });
            }
        }, this.unkownErrorCallback);
    }


    render() {
        return <div className="container-fluid">
            <Tick onTick={this.calcBalance} />
            <AppFloatingActions>
                <Link to="pay" className="btn btn-primary btn-lg"><Icon icon="faPlus" /></Link>
            </AppFloatingActions>
            {this.state.customer && <Card title="Customer">
                <FormLabel value={this.state.customer.cust_num} label="Cust #" />
                <FormLabel value={this.state.customer.cust_name} label="Customer" />
            </Card>}
            <Card title="Statement">
                <div className="row text-center">
                    <div className="col">
                        <FormLabel value={this.state.item.trans_num} label="Transaction" />
                    </div>
                    <div className="col">
                        <FormLabel value={this.state.item.transactionType} label="Type" />
                    </div>
                    <div className="col">
                        <FormLabel value={Globals.toMDYLocalDateString(this.state.item.trans_date)} label="Date" />
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col">
                        <FormLabel value={Globals.formatPrice(this.state.item.trans_amt)} label="Amount" />
                    </div>
                    <div className="col">
                        <FormLabel value={Globals.formatPrice(this.state.item.possible_disc)} label="Discount" />
                    </div>
                    <div className="col">
                        <FormLabel value={Globals.formatPrice(this.state.item.balance)} label="Balance" />
                    </div>
                </div>
            </Card>
            <Card title="Payments">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Cash ID</th>
                            <th>Discount</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.cashList?.map((cash, i) => <tr key={i}>
                            <td>{cash.cash_id}</td>
                            <td>{Globals.formatPrice(cash.discountAmount)}</td>
                            <td>{Globals.formatPrice(cash.dep_amount)}</td>
                        </tr>)}
                    </tbody>
                </table>
                {this.state.cashList?.length === 0 && <Alert><L>No payments in record.</L></Alert>}
            </Card>
        </div>;
    }
}

export default withParamsAndNavigate(StatementView);