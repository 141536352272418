import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import DataTable from "./DataTable";
import FormInput from "./FormInput";
import Icon from "./Icon";
import ItemCatalogCategory from "./ItemCatalogCategory";
import ItemView from "./ItemView";
import L from "./Lang";
import LoadingAlert from "./LoadingAlert";
import ScrollHandler from "./ScrollHandler";
import Tick from "./Tick";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import { BarcodeScanner } from "./BarcodeScanner";

class ItemCatalog extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            query: {},
            page: 1,
            eol: false,
            categoryFilter: props.categoryFilter
        };
        this.load = this.load.bind(this);
        this.finish = this.finish.bind(this);
        this.setActiveItems = this.setActiveItems.bind(this);
    }

    componentDidMount() {
        this.load();
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.state.categoryFilter !== prevState.categoryFilter)
            this.props.onFilterChange(this.state.categoryFilter);
    }


    setActiveItems(list) {
        if (this.props.selectedItems) {
            list.forEach(i => {
                var find = this.props.selectedItems.find(x => x.item_num === i.item_num);
                if (find)
                    i.className = 'active';
            });
        }
    }

    concatList = (list) => {
        let newList = [...this.state.list];
        list.forEach(i => {
            var find = newList.find(x => x.item_num === i.item_num);
            if(!find)
                newList.push(i);
        });
        return newList;
    }

    load(page, search, reset, cat) {
        if (reset)
            this.setState({ list: [], eol: false, loading: true });
        if (!page)
            page = this.state.page;
        else
            this.setState({ page: page });
        if (!search && !reset)
            search = this.state.search;
        else
            this.setState({ search: search });
        if (!cat && cat !== null)
            cat = this.state.categoryFilter;
        else
            this.setState({ categoryFilter: cat });
        this.setState({ eol: true });
        qreq.get('/api/item/list?custID=' + this.props.customerID + '&page=' + page + (search ? '&search=' + encodeURIComponent(search) : '') + (cat ? '&categoryID=' + cat.id : ''), j => {
            if (j.list) {
                this.setActiveItems(j.list);
                if (!this.state.list)
                    this.setState({ list: j.list, eol: false });
                else if (j.list.length === 0)
                    this.setState({ eol: true });
                else
                    this.setState({ list: this.concatList(j.list), eol: false })
            }
            else {
                this.alert(j.errorMessage);
                this.setState({ eol: false });
            }
            this.setState({ loading: false });
        }, e => {
            this.setState({ eol: false, loading: false });
            this.unkownErrorCallback();
        });
    }

    finish(item, info) {
        var list = [...this.state.list];
        var find = list.find(x => x.item_num === item.item_num);
        find.className = 'active';
        this.setState({ selectedItem: null, showFilter: false, list: list });
        this.props.onAddItem(item, info);
        this.rescroll();
    }

    offset(el) {
        let rect = el.getBoundingClientRect();
        let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft;
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    rescroll = () => {
        setTimeout(() => {
            window.scrollTo(0, this.state.scrollY - 100)
            document.body.scrollTo(0, this.state.scrollY - 100);
        }, 100);
    }

    scanBarcode = () => {
        BarcodeScanner.show((code) => {
            this.setState({ query: { ...this.state.query, search: code } });
            this.load(1, code, true);
        });
    }

    updateItemImage = (item) => {
        var list = [...this.state.list];
        var find = list.find(x => x.id === item.itemID);
        if(find) {
            find.imageURL = item.imageURL;
            this.setState({ list: list });
        }
    }

    render() {
        if (this.state.selectedItem) {
            return <ItemView onAddItem={this.finish} customerID={this.props.customerID} item={this.state.selectedItem} onCancel={() => { this.setState({ selectedItem: null }); this.rescroll(); }} onImageUpload={(item) => this.updateItemImage(item)} />
        }
        if (this.state.showFilter) {
            return <ItemCatalogCategory onFilter={cat => { this.setState({ showFilter: false }); this.load(1, null, true, cat); }} onCancel={() => { this.setState({ showFilter: false }); this.rescroll(); }} />
        }
        return <div className="container-fluid">
            <div className="app-actions app-actions-form">
                {this.state.categoryFilter ? <button type="button" className="btn btn-primary btn-lg" onClick={() => { this.setState({ categoryFilter: null, query: { ...this.state.query, search: null } }); this.load(1, null, true, null); }}><Icon icon="faFilterCircleXmark" /></button>
                    : <button type="button" className="btn btn-primary btn-lg" onClick={() => this.setState({ showFilter: true })}><Icon icon="faFilter" /></button>}
            </div>
            <div className="my-3 d-flex gap-1 app-form-actions">
                <div>
                    <button type="button" className="btn btn-secondary btn-lg" onClick={this.props.onCancel}><Icon icon="faArrowLeft" /> <L>Back</L></button>
                </div>
                <div className="flex-fill text-end">
                    <button type="button" className="btn btn-secondary btn-lg" onClick={this.props.onCancel}><Icon icon="faCheck" /> <L>Done</L></button>
                </div>
            </div>
            {this.state.list ? <>
                <FormInput model={this.state.query} name="search" type="text" label="Search..." labelAsPlaceholder onChange={() => this.setState({ query: { ...this.state.query }, searching: true })} postAddon={<span role="button" onClick={this.scanBarcode}><Icon icon="faBarcode" /></span>} />
                <Tick onTick={() => {
                    if (this.state.searching && this.state.query.search !== this.state.search) {
                        this.setState({ searching: false });
                        this.load(1, this.state.query.search, true);
                    }
                }} />
                {this.state.loading ? <LoadingAlert /> :
                    <DataTable className="list-table" columns={[
                        {
                            name: 'image', title: 'Image', onClick: (row, e) => {
                                if(e) {
                                    this.setState({ scrollY: this.offset(e.target).top });
                                }
                                this.setState({ selectedItem: row });
                            }, valueFunc: row => {
                                return <img src={Globals.serverUrl + row.imageURL} alt="" className="img-fluid product-img" />
                            }, className: 'text-center'
                        },
                        {
                            name: 'desc_1', title: 'Description', onClick: (row, e) => {
                                if(e) {
                                    this.setState({ scrollY: this.offset(e.target).top });
                               }
                                this.setState({ selectedItem: row  });
                            }, valueFunc: row => {
                                return <><div>{row.desc_1}</div><div>{Globals.formatPrice(row.price)}</div></>;
                            }, className: 'product-title'
                        },
                        { name: 'sa_item2', title: <>Category<span className="d-none d-md-inline"> Code</span></>, className: 'd-none d-md-table-cell' },
                        { name: 'categoryName', title: 'Category', className: 'd-none d-md-table-cell' },
                        { name: 'item_num', title: 'Item Code', className: 'd-none d-md-table-cell' },

                    ]} data={this.state.list} />}
                <ScrollHandler onScroll={() => this.load(this.state.page + 1)} end={this.state.eol} />
            </>
                : <LoadingAlert />}
        </div>;
    }
}

export default withParamsAndNavigate(ItemCatalog);