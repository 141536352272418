import Quagga from 'quagga';
import BaseCom from "./BaseCom";
import React from "react";
import './BarcodeScanner.css';

export const BarcodeScanner = {
    show () {}
};

export class BarcodeScannerCom extends BaseCom {
    constructor(props) {
        super(props);
        this.cameraRef = React.createRef();
        BarcodeScanner.show = this.show;
    }

    show = (callback) => {
        this.callback = callback;
        this.setState({ show: true });
        setTimeout(this.init, 100);
    }

    hide = () => {
        this.setState({ show: false });
        Quagga.stop();
    }

    init = () => {
        Quagga.onDetected(data => {
            if(data.codeResult?.code) {
                console.log('Code detected', data.codeResult.code);
                this.setState({ show: false });
                this.callback?.(data.codeResult.code);
                Quagga.stop();
            }
        });
        Quagga.init({
            inputStream : {
              name : "Live",
              type : "LiveStream",
              target: this.cameraRef.current
            },
            decoder : {
              readers : ["upc_reader", "upc_e_reader"]
            }
          }, function(err) {
              if (err) {
                  console.log(err);
                  return
              }
              console.log("Initialization finished. Ready to start");
              Quagga.start();
          });
    }

    render() {
        if(!this.state.show)
            return null;
        return <div className="barcode-scanner">
            <div className="barcode-scanner-close" onClick={this.hide}>X</div>
            <div className="barcode-scanner-overlay">
                <div className="barcode-scanner-overlay-text">Scanning...</div>
            </div>
            <div ref={this.cameraRef} className="barcode-scanner-camera"></div>
        </div>;
    }
}