import { Link } from "react-router-dom";
import BaseCom from "./BaseCom";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import { qreq } from "../shared/qrequest";
import Globals from "../shared/Globals";
import Icon from "./Icon";
import { NavMenuGlobal } from "./NavMenu";
import Alert from "./Alert";
import L from "./Lang";
import LoadingAlert from "./LoadingAlert";
import AppFloatingActions from "./AppFloatingActions";

class StatementList extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        };
        this.load = this.load.bind(this);
    }

    componentDidMount() {
        NavMenuGlobal.setTitle('A/R');
        this.load();
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    load() {
        qreq.get('/api/ar/customer/list', j => {
            if (j.list)
                this.setState({ list: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    exportPDF() {
        window.open(Globals.serverUrl + '/api/ar/export/render/pdf/0/ar-statement.pdf', '_blank');
    }

    render() {
        return <div className="container-fluid">
            {this.state.list ? this.state.list.length === 0 ? <Alert><L>There are no statements to show.</L></Alert> :
            <div className="list-group">
                {this.state.list.map(i => <div key={i.id} className="list-group-item" role="button" onClick={() => this.props.navigate('/console/statement/' + i.id)}>
                    <div>{i.cust_name}</div>
                    <small>{Globals.formatPrice(i.pendingBalance)}</small>
                </div>)}
            </div> : <LoadingAlert />}

            
            <AppFloatingActions>
                <button type="button" className="btn btn-primary btn-lg d-block" onClick={this.exportPDF}><Icon icon="faFilePdf" /></button>
            </AppFloatingActions>
        </div>;
        
    }
}

export default withParamsAndNavigate(StatementList);