import BaseCom from "./BaseCom";
import "./ImageView.css";
import L from "./Lang";

export const ImageView = {
    init(show) {
        this.show = show;
    },
    show(imageUrl) {} // placeholder
}

export class ImageViewCom extends BaseCom {
    constructor(props) {
        super(props);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        ImageView.init(this.show);
    }

    show(imageUrl) {
        this.setState({ imageUrl: imageUrl, show: true });
    }

    hide() {
        this.setState({ show: false });
    }

    render() {
        if(!this.state.show)
            return null;
        return <div className="ImageView" onClick={this.hide} style={{backgroundImage: 'url(\'/assets/img/pattern-bg.png\')'}}>
            <img src={this.state.imageUrl} alt="" className="img-fluid" />
            <p className="text-center"><L>Click anywhere to close.</L></p>
        </div>;
    }
}